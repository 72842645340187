<template>
<div>
    <div class="articleContainer">
        <div class="article">
            <p style="text-align: center;">
                <strong>
                    <span>商户服务协议</span>
                </strong>
            </p>
            <p>
                <span>&nbsp;</span>
            </p>
            <p>
                <span>&nbsp;&nbsp;&nbsp;</span>
                <span>特别提醒：在接受本合同之前，请您仔细阅读本合同的全部内容（特别是以下划线标注的内容）。如果您对本合同的条款有疑问的，请通过微在线网客服渠道进行询问，微在线网将向您解释条款内容。如果您不同意本合同的任意内容，或者无法准确理解微在线网对条款的解释，请不要同意本合同或使用本合同项下的服务，否则，表示您已接受了以下所述的条款和条件，同意受本合同约束。届时您不应以未阅读本合同的内容或者未获得微在线网对您问询的解答等理由，主张本合同无效，或要求撤销本合同。</span>
            </p>
            <p>
                <span>&nbsp;&nbsp;</span>
                <span>
                    为充分维护您的合法权益，微在线网特别提醒您特别关注本合同条款中的黑体及<span>/</span>
                    或加下划线部分，请您务必仔细阅读。
                </span>
            </p>
            <p>
                <span>一、定义条款</span>
            </p>
            <p>
                <span>您确认并同意本条款中对于各名词的解释及定义，并同意按照该定义履行相关义务。</span>
            </p>
            <p>
                <span>1.1</span>
                <span>
                    平台（以下统称为“平台”）：特指由微在线现在或将来拥有合格权限运营<span>/</span>
                    管理的，提供团购等技术服务的网络服务平台，包括但不限于微在线网、点评网、微在线关联方、微在线合作方运营<span>/</span>
                    管理的网络服务平台，及未来可能新设或合作的网络平台等。
                </span>
            </p>
            <p>
                <span>1.2</span>
                <span>
                    用户：即在平台注册，并使用其本人注册的账户，通过平台展示的信息获取商品<span>/</span>
                    服务的个人。
                </span>
            </p>
            <p>
                <span>1.3</span>
                <span>
                    团购信息：您通过平台发布的，在平台页面上展示的您商品<span>/</span>
                    服务<span>/</span>
                    优惠信息（如商品<span>/</span>
                    服务描述、兑换券有效期、团购规则、门店信息、会员优惠等）。该信息为您就前述商品<span>/</span>
                    服务向用户发出的要约，一旦用户通过平台确认同意购买您该商品<span>/</span>
                    服务，即视为您与用户达成了相应合同。
                </span>
            </p>
            <p>
                <span>1.4</span>
                <span>商家中心：为完成合作内容，微在线基于合同为您提供的管理系统，包括“商家中心”、“开店宝”等 。</span>
            </p>
            <p>
                <span>1.5</span>
                <span>
                    团购：针对由一定数量的用户组团，通过平台以较低折扣购买您通过平台发布的商品<span>/</span>
                    服务，并最终由您提供相应商品<span>/</span>
                    服务的网络交易行为。
                </span>
            </p>
            <p>
                <span>1.6</span>
                <span>
                    兑换券：指用户通过平台获取的，您向用户提供商品<span>/</span>
                    服务的消费凭证。兑换券包括但不限于团购券、平台订单等，展现方式包括但不限于字符、二维码、订单、短信、电子邮件等。
                </span>
            </p>
            <p>
                <span>1.7</span>
                <span>
                    兑换券消费数：用户已实际消费的兑换券数量。鉴于您提供的商品<span>/</span>
                    服务通过商家中心进行验证为用户实际消费的依据，故兑换券消费数以平台验证已标记消费的数量为准。
                </span>
            </p>
            <p>
                <span>1.8</span>
                <span>
                    门店价：签订本合同时，您提供的商品<span>/</span>
                    服务直接对外销售<span>/</span>
                    提供时的单份销售价格。
                </span>
            </p>
            <p>
                <span>1.9</span>
                <span>
                    平台价：您提供的商品<span>/</span>
                    服务通过平台向用户展示的价格。
                </span>
            </p>
            <p>
                <span>1.9.1</span>
                <span>团购平台价为参加平台团购活动时的单份销售价格。</span>
            </p>
            <p>
                <span>1.10</span>
                <span>
                    代收款项：微在线<span>/</span>
                    微在线关联方<span>/</span>
                    微在线合作方代您收取的，用户通过平台向您支付的已实际消费的兑换券对应的款项等功能对应的消费款项。该款项扣除双方约定的服务费后，由微在线<span>/</span>
                    微在线关联方<span>/</span>
                    微在线合作方支付给您。
                </span>
            </p>
            <p>
                <span>1.10.1</span>
                <span>团购代收款项计算标准为：团购平台价×兑换券消费数。</span>
            </p>
            <p>
                <span>1.11</span>
                <span>服务费：为了实现您进入平台开展经营活动、进行交易之目的，微在线为您提供本合同约定之服务收取的相关费用。</span>
            </p>
            <p>
                <span>1.11.1</span>
                <span>团购技术服务的服务费为截至兑换券有效期截止之日，兑换券消费数×服务费价格。</span>
            </p>
            <p>
                <span>1.12</span>
                <span>服务费价格：用户每实际消费一张兑换券每实际消费一次，您应向微在线支付的服务费数额。团购及其它功能服务费计算标准以双方通过书面、邮件或商家中心确认的比例为准。</span>
            </p>
            <p>
                <span>1.13</span>
                <span>
                    结算价：针对每一张已实际消费的兑换券，于代收款项中扣除应收取的服务费、商家自促费等其他费用后，应支付给您的数额。结算价<span>=</span>
                    团购平台价<span>-</span>
                    团购服务费价格<span>-</span>
                    商家自促费。
                </span>
            </p>
            <p>
                <span>1.14</span>
                <span>代收净额：对应用户实际消费的兑换券数量，应支付给您的结算款项。</span>
            </p>
            <p>
                <span>1.14.1</span>
                <span>团购技术服务代收净额计算标准为：团购结算价×兑换券消费数。</span>
            </p>
            <p>
                <span>二、服务内容</span>
            </p>
            <p>
                <span>
                    为实现您进入平台开展经营活动、通过平台发布商品<span>/</span>
                    服务信息、与用户进行交易之目的，微在线为您提供授权许可计算机软件及软件技术服务、网络技术服务及其他相关服务，具体服务内容如下：
                </span>
            </p>
            <p>
                <span>2.1</span>
                <span>团购技术服务</span>
            </p>
            <p>
                <span>
                    微在线<span>/</span>
                    微在线关联方<span>/</span>
                    微在线合作方为您提供商家中心等系统平台、软硬件设备，以实现您商品<span>/</span>
                    服务在线展示，为用户提供兑换券验证、汇总管理经营数据等经营行为，并实现代您收取商品<span>/</span>
                    服务对应的款项的技术服务。
                </span>
            </p>
            <p>
                <span>2.1.1</span>
                <span>授权许可您使用微在线所有或合法运营、管理的与合作内容相关的计算机软件，并提供软件技术服务。</span>
            </p>
            <p>
                <span>2.1.2</span>
                <span>微在线以自有技术、知识，为您解决其在平台经营过程中遇到的网络技术问题。例如，针对您使用“平台验证系统”进行技术指导和专业培训。</span>
            </p>
            <p>
                <span>2.1.3</span>
                <span>
                    技术服务费结算：微在线<span>/</span>
                    微在线合作方<span>/</span>
                    微在线关联方通过平台代您收取商品<span>/</span>
                    服务对应款项，并将代收净额支付给您。
                </span>
            </p>
            <p>
                <strong>
                    <span>三、服务费用及支付方式</span>
                </strong>
            </p>
            <p>
                <strong>
                    <span>
                        基于上述服务，您应按照约定支付服务费。您同意并确认，微在线<span>/</span>
                        微在线关联方<span>/</span>
                        微在线合作方可于代收款项中直接扣除上述服务费后，将代收净额支付给您。
                    </span>
                </strong>
            </p>
            <p>
                <span>四、您的权利义务</span>
            </p>
            <p>
                <span>4.1</span>
                <span>您应遵守本合同之约定及平台公告发布的与您履行本合同项下义务相关的各项规则。</span>
            </p>
            <p>
                <strong>
                    <span>4.2</span>
                </strong>
                <strong>
                    <span>
                        您应保证您为一家依据中国法律合法成立并有效存续的法人或其它商事主体，能够独立承担法律责任，并具有履行本合同所需的一切权利及能力；您因资质或提供的您信息而致本合同无法正常履行的，由此产生的一切结果由您负责，您还需赔偿微在线<span>/</span>
                        微在线关联方<span>/</span>
                        微在线合作方因此遭受的损失。
                    </span>
                </strong>
            </p>
            <p>
                <strong>
                    <span>4.3 </span>
                </strong>
                <strong>
                    <span>您承诺向微在线提交的证明材料合法、真实、准确详尽。否则您承诺独立承担因此导致的相应责任及后果，使微在线免责；</span>
                </strong>
            </p>
            <p>
                <strong>
                    <span>4.4 </span>
                </strong>
                <strong>
                    <span>您承诺通过商户中心发布、编辑、更新的商户信息（包括但不限于文字、图片等）真实准确，并且不侵犯任何人的合法权益；</span>
                </strong>
            </p>
            <p>
                <strong>
                    <span>4.5</span>
                </strong>
                <strong>
                    <span>
                        您向用户提供商品<span>/</span>
                        服务时，应确保与您信息一致，您在兑换券有效期内，应按照本合同约定、信息描述、订单及微在线相关规则接受用户的预约和消费。因您原因造成的用户投诉、经济损失等相关后果均由您承担。
                    </span>
                </strong>
            </p>
            <p>
                <strong>
                    <span>4.6</span>
                </strong>
                <strong>
                    <span>
                        您应合法经营，且提供的商品<span>/</span>
                        服务应符合国家法律、法规、规章的规定。如因您行为或您提供的商品<span>/</span>
                        服务造成用户损失的，由您承担相应的赔偿责任。如微在线<span>/</span>
                        微在线关联方<span>/</span>
                        微在线合作方基于前述情况向消费者先行赔付的，微在线有权进行追偿。
                    </span>
                </strong>
            </p>
            <p>
                <strong>
                    <span>4.7</span>
                </strong>
                <strong>
                    <span>您应按照平台价为用户开具相应金额的发票。</span>
                </strong>
            </p>
            <p>
                <strong>
                    <span>4.8</span>
                </strong>
                <strong>
                    <span>您应保证合作期间经营场所内实际销售价格不低于平台价，且不得唆使或诱使持兑换券消费的用户放弃兑换券而改为使用现金或其他形式消费，否则微在线有权立即终止本合同并要求您按照本合同约定承担违约责任。</span>
                </strong>
            </p>
            <p>
                <strong>
                    <span>4.9</span>
                </strong>
                <strong>
                    <span>
                        对于虽已成交但并未实际消费的兑换券，无论该兑换券有效期是否届满，您同意微在线<span>/</span>
                        平台实际经营者均有权留存该兑换券所对应的款项，并根据订单的约定及用户的要求与意愿，处置相应款项。
                    </span>
                </strong>
            </p>
            <p>
                <strong>
                    <span>4.10</span>
                </strong>
                <strong>
                    <span>您应在用户消费时及时通过商家中心验证兑换券，因您怠于履行验证义务而导致的一切损失由您自行承担。</span>
                </strong>
            </p>
            <p>
                <strong>
                    <span>4.11</span>
                </strong>
                <strong>
                    <span>
                        您承诺并保证您对使用的商品、商标、品牌、图片等享有合法权利或合法授权，不会侵犯他人的知识产权、企业名称权等权益；且您承诺微在线<span>/</span>
                        微在线关联方<span>/</span>
                        微在线合作方不会因展示您上述信息而遭受来自第三方的任何投诉、抗辩及争议，否则相应责任均由您承担。
                    </span>
                </strong>
            </p>
            <p>
                <strong>
                    <span>4.12</span>
                </strong>
                <strong>
                    <span>如微在线在合作过程中提供了相应设备、设施给您使用，您确认前述设备的所有权属于微在线，您仅有使用权并应尽到妥善保管义务，且基于前述设备的使用按微在线要求向您提供相应数据。因您原因造成的设备损毁丢失，您应进行相应赔偿（具体价格双方可选择采用书面、邮件或商家中心操作任一种方式确认）。</span>
                </strong>
            </p>
            <p>
                <strong>
                    <span>4.13</span>
                </strong>
                <strong>
                    <span>无论本协议因任何原因终止，您承诺立即停止以任何方式使用微在线相关的商标、标识、网站名称、网站内容等。</span>
                </strong>
            </p>
            <p>
                <strong>
                    <span>4.14</span>
                </strong>
                <strong>
                    <span>您同意用户通过平台发布的针对您的评论、照片等，纯属用户个人意见，与微在线立场无关，微在线不承担任何责任。</span>
                </strong>
            </p>
            <p>
                <strong>
                    <span>4.15</span>
                </strong>
                <strong>
                    <span>
                        您应按照合同约定、页面信息及平台方规则向用户提供商品<span>/</span>
                        服务，否则由此引起用户投诉或给用户<span>/</span>
                        微在线<span>/</span>
                        微在线关联方<span>/</span>
                        微在线合作方造成损失的，相应责任均由您承担，且微在线有权解除合同并要求您按本合同约定支付违约金。
                    </span>
                </strong>
            </p>
            <p>
                <strong>
                    <span>4.16</span>
                </strong>
                <strong>
                    <span>
                        您不得通过任何手段、以任何方式自行或者委托他人进行如下行为（包括但不限于虚拟交易、虚拟验证、自买自卖、伪造网络用户真实消费、体验、刷单等违反平台规则、损害微在线<span>/</span>
                        微在线关联方<span>/</span>
                        微在线合作方利益的行为，或伪造、仿冒等其他违反国家法律法规的行为，或其它违反公平竞争诚实信用原则与商业道德的行为），否则，微在线有权解除合同，在代收净额中扣除上述行为给微在线<span>/</span>
                        微在线关联方<span>/</span>
                        微在线合作方造成的损失，并要求您按照本合同约定支付违约金。
                    </span>
                </strong>
            </p>
            <p>
                <strong>
                    <span>4.17</span>
                </strong>
                <strong>
                    <span>
                        如在合同履行期限内，因您原因造成的方案、项目或合同中止（包括但不限于服务方案已下线或停止营业），您应及时通知微在线。如因您怠于通知造成的一切纠纷和责任（包括但不限于用户投诉、行政机关处罚等）由您承担，若由此给微在线<span>/</span>
                        微在线关联方<span>/</span>
                        微在线合作方造成经济损失，您应进行赔偿。
                    </span>
                </strong>
            </p>
            <p>
                <strong>
                    <span>4.18</span>
                </strong>
                <strong>
                    <span>您保证双方合作项目在其它平台销售的平台价不得低于本合同涉及平台价，否则微在线有权解除合同。</span>
                </strong>
            </p>
            <p>
                <span>五、微在线的权利义务</span>
            </p>
            <p>
                <span>5.1</span>
                <span>微在线应向您提供本合同约定的技术服务及其他相关服务。</span>
            </p>
            <p>
                <strong>
                    <span>5.2</span>
                </strong>
                <strong>
                    <span>
                        为更好的推荐您的商品<span>/</span>
                        服务，以提升您的知名度<span>/</span>
                        订单量，微在线可通过适当的方式和渠道做相关宣传介绍，并进行相关推荐<span>/</span>
                        推广。您对上述行为予以认可，并授权微在线<span>/</span>
                        微在线关联方<span>/</span>
                        微在线合作方就您及其相关门店的名称、<span>LOGO</span>
                        、图片、文字、门店、会员优惠等信息进行使用。
                    </span>
                </strong>
            </p>
            <p>
                <strong>
                    <span>5.3</span>
                </strong>
                <strong>
                    <span>微在线有权根据本合同的约定向您收取服务费，并且有权先从代收净额中直接扣除该等服务费。</span>
                </strong>
            </p>
            <p>
                <strong>
                    <span>5.4</span>
                </strong>
                <strong>
                    <span>
                        微在线有权对您拟在平台发布的内容、信息进行形式审核，必要时微在线有权依据法律法规、政策规定及平台规则拒绝发布<span>(</span>
                        如商家拟发布涉嫌黄、赌、毒及其他违法或者违反公序良俗的信息<span>)</span>
                        。
                    </span>
                </strong>
            </p>
            <p>
                <strong>
                    <span>5.5</span>
                </strong>
                <strong>
                    <span>除展示信息内容或文字确有错误外，您不得要求微在线修改已发布于平台上的您信息。</span>
                </strong>
            </p>
            <p>
                <strong>
                    <span>5.6</span>
                </strong>
                <strong>
                    <span>微在线有权在确保双方约定结算价不变的前提下，根据市场实际需求适度调整平台价，并在价格调整后通知您。</span>
                </strong>
            </p>
            <p>
                <strong>
                    <span>5.7</span>
                </strong>
                <strong>
                    <span>
                        如用户就您商品<span>/</span>
                        服务向微在线<span>/</span>
                        平台实际经营者投诉，微在线经核实后，有权要求您配合解决该投诉，在该投诉未解决之前微在线<span>/</span>
                        微在线关联方<span>/</span>
                        微在线合作方有权拒绝向您支付涉及投诉的代收净额，待投诉解决后再行支付。
                    </span>
                </strong>
            </p>
            <p>
                <strong>
                    <span>5.8</span>
                </strong>
                <strong>
                    <span>微在线有权升级、更新商家中心系统，如有变更，微在线将通过前述系统以公告形式作出通知。</span>
                </strong>
            </p>
            <p>
                <strong>
                    <span>六、违约责任</span>
                </strong>
            </p>
            <p>
                <strong>
                    <span>6.1</span>
                </strong>
                <strong>
                    <span>
                        除非本合同另有约定，如您违反诚信原则，或违反本合同的任一条款或平台上的任何规则，或您违反其在本合同的履行中做出的任何承诺和保证，或您提供的资质、证照等信息不属实，则微在线及其关联方有权停止向您支付代收净额，并有权要求您在一定期限内纠正违约行为。如您在微在线要求的期限内纠正违约行为后符合本合同约定的，微在线应恢复向您支付代收净额；如您在微在线要求的期限内拒绝纠正或纠正后仍不符合本合同约定的，微在线有权单方解除本合同，并有权要求您按照自本合同生效之日起累计至您发生违约行为之日的代收款项总额的<span>30%</span>
                        向其支付违约金。
                    </span>
                </strong>
            </p>
            <p>
                <span>6.2</span>
                <span>因微在线未能按照合同约定履行义务包括未及时付款，且在您要求的期限内拒绝纠正或纠正后仍不符合本合同约定的，应承担因此给您造成的损失。</span>
            </p>
            <p>
                <span>七、保密义务</span>
            </p>
            <p>
                <span>除另有约定外，本合同任何一方均应对其获得的对方之业务、经营、财务状况和其他保密资料予以严格保密，不得以任何形式向任何第三方披露。否则，因此给其他方造成任何损失的，违约方应承担因此给守约方造成的全部损失。保密义务在合同有效期间及终止后始终有效，不因本合同其他条款整体或部分无效、被撤销而失效。</span>
            </p>
            <p>
                <span>八、合同终止和解除</span>
            </p>
            <p>
                <span>本合同期限届满或本合同约定的其他终止情形发生时，本合同终止。除合同另有约定外，合同履行过程中，一方经书面通知对方且经双方协商一致后，可解除本合同。本合同终止或解除后：</span>
            </p>
            <p>
                <strong>
                    <span>8.1</span>
                </strong>
                <strong>
                    <span>微在线有权删除平台上已发布的您信息，且有权拒绝提供相关信息；</span>
                </strong>
            </p>
            <p>
                <strong>
                    <span>8.2</span>
                </strong>
                <strong>
                    <span>本合同终止前您已与用户达成的尚未履行完毕的订单，您应按照本合同、信息、及订单、平台规则等的约定继续履行相应义务；</span>
                </strong>
            </p>
            <p>
                <strong>
                    <span>8.3</span>
                </strong>
                <strong>
                    <span>您同意微在线保存您资质信息、商品信息、交易记录等相关资料。</span>
                </strong>
            </p>
            <p>
                <strong>
                    <span>九、免责条款</span>
                </strong>
            </p>
            <p>
                <strong>
                    <span>因战争、自然灾害、政府行为、互联网系统故障、互联网通讯提供商故障等不可抗力因素或第三方原因导致微在线不能履行本合同项下义务，微在线无须承担责任。</span>
                </strong>
            </p>
            <p>
                <span>十、法律适用及争议解决条款</span>
            </p>
            <p>
                <span>10.1 </span>
                <span>本协议的成立、有效性、解释、履行、签署、修订和终止以及争议的解决均应适用中华人民共和国法律。</span>
            </p>
            <p>
                <strong>
                    <span>10.2</span>
                </strong>
                <strong>
                    <span>因本合同引起的或与本合同有关的任何争议，双方应协商解决。协商不成的，任何一方均可将争议提交至原告所在地法院。</span>
                </strong>
            </p>
            <p>
                <span>十一、附则</span>
            </p>
            <p>
                <span>11.1</span>
                <span>与本合同有关的全部通知，双方可选择采用书面、邮件或商家中心操作任一种方式变更及确认。</span>
            </p>
            <p>
                <span>11.2</span>
                <span>本合同正文不得涂改修订，如有未尽事宜，应另行签订书面补充合同。任何形式的口头协议均属无效。</span>
            </p>
            <p>
                <span>11.3</span>
                <span>本合同附件是本合同不可分割的组成部分，与本合同具有同等法律效力。</span>
            </p>
            <p>
                <span>11.4</span>
                <span>
                    本合同由您确认后，于您的团购服务信息首次发布之日起生效，有效期<span>1</span>
                    年。
                </span>
            </p>
            <p>
                <span>11.5</span>
                <span>
                    合同到期后，在保留合同条款及相应约定情形下，经微在线同意有效期自动顺延，每次顺延期为<span>1</span>
                    年。
                </span>
            </p>
            <p>
                <span>
                    <br>
                </span>
            </p>
        </div>
    </div>
</div>
</template>

<script>
export default {

}
</script>

<style scoped>
.articleContainer{
    padding:10px 20px;
}
</style>
